.optInput {
  width: 48px;
  height: 45px;
  margin:9.1px;
}
.optInput input{
  width: 45px !important;
  height: 45px;
  font-size: 24px;
}
.optInput span{
  margin: 0px 4px;
}